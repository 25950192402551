import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { BaseTitle, LgTitle, SmTitle } from '../elements/Titles';
import { BaseParagraph, XsParagraph } from '../elements/Paragraphs';
import { UnorderedList } from '../elements/Lists';

const TermsOfUse = () => (
  <Layout>
    <SEO title="Terms of Use" />
    <Container>
      <Row>
        <Column>
          <LgTitle>Terms of Use</LgTitle>
          <XsParagraph>
            The quick reference is provided as a readers' aid — the full policy
            governs if there is any inconsistency.
          </XsParagraph>
        </Column>
      </Row>
      <Row hideMobile="true" textAlign="center">
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" marginBottom="0">
            The full policy
          </SmTitle>
        </Column>
        <Column width="1/3">
          <SmTitle dark="true" marginBottom="0">
            The quick reference
          </SmTitle>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>1. Effective Date</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Your use of this Site after May 24, 2023 is governed by these
            terms.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            This policy takes effect on May 24, 2023.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>2. Introduction</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            These are the terms of use for this Site.
          </BaseParagraph>
          <BaseParagraph>
            By using this Site you are deemed to agree with these terms. It will
            change from time to time, so keep an eye on this page. We include
            the revision date on the header on the main page for your easy
            reference.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Welcome to the digital properties of Wise Publishing, Inc., which
            include the Internet sites and software applications that you access
            or download to view Content of Wise Publishing, Inc. (collectively
            "Sites" or each a "Site"). You agree to be bound by the following
            terms and conditions of use ("Terms of Use"), together with our
            Privacy Policy and other policies on the Site.
          </XsParagraph>
          <XsParagraph>
            Your use of the Site constitutes your agreement to these Terms of
            Use. If you disagree with any part of these Terms of Use, please do
            not use the Site. Wise Publishing, Inc., in its sole discretion and
            without notice to you, reserves the right to make changes to the
            Site and these Terms of Use from time to time.
          </XsParagraph>
          <XsParagraph>
            If we make any material changes, we will take reasonable steps to
            notify you. You may wish to check this section from time to time,
            because by visiting the Site, you agree to accept any such changes.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>3. Definitions</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            The definitions are here. If a term doesn't make sense, look here.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            The following words and expressions have the following meanings
            unless inconsistent with the context:
          </XsParagraph>
          <XsParagraph>
            "<strong>Commercial Gain</strong>"
          </XsParagraph>
          <XsParagraph>
            includes, without limitation, any use of this Site which may result
            in revenue generation or personal gain, including advertising,
            selling goods to a third party and copying, reproducing,
            distributing, publishing, transferring, transmitting, licensing,
            selling, commercially exploiting or duplicating any element of this
            Site whatsoever;
          </XsParagraph>
          <XsParagraph>
            "<strong>Content</strong>"
          </XsParagraph>
          <XsParagraph>
            means any media that can be rendered on a web page intended for
            consumption by an end-user, including, but not limited to articles,
            quizzes, polls, surveys, videos, pictures, audio and embeds;
          </XsParagraph>
          <XsParagraph>
            "<strong>Personal Information</strong>"
          </XsParagraph>
          <XsParagraph>
            means information about an identifiable individual. Personal
            Information includes facts which, when combined with other
            information, can identify an individual;
          </XsParagraph>
          <XsParagraph>
            "<strong>Service</strong>"
          </XsParagraph>
          <XsParagraph>
            means a product offering on a Wise Publishing, Inc. website;
          </XsParagraph>
          <XsParagraph>
            "<strong>we</strong>", "<strong>us</strong>" or "
            <strong>our</strong>"
          </XsParagraph>
          <XsParagraph>
            Wise Publishing, Inc., a company duly incorporated under the laws of
            the province of Ontario;
          </XsParagraph>
          <XsParagraph>
            "<strong>you</strong>", "<strong>your</strong>"
          </XsParagraph>
          <XsParagraph>
            an individual, company or firm accessing our Site.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>4. Access</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If you are under 16, you must get permission to use the Site and in
            any event be older than 13.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You must be older than 13 years to register as a user or access the
            Site. If you are under 16 please get a parent or guardian's
            permission before making any contribution.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If you provide permission, you are responsible for the minor's
            activity. And you understand that certain Content may not be
            appropriate.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            If you have authorized a minor to access a Wise Publishing, Inc.
            Site, then you agree to be fully responsible for: (i) controlling
            the minor's access to and use of that Wise Publishing, Inc. Site;
            and (ii) the consequences of any misuse by the minor. You
            acknowledge that some of the areas of a Wise Publishing, Inc. Site
            and some of the Content may include material that is inappropriate
            for minors.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            While we try to provide 24-hour access, it may not always be
            available. If so, we are not liable for any reason.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            While we endeavour to ensure that this Site is normally available
            twenty-four (24) hours a day, we shall not be liable for any reason
            this Site is unavailable at any time or for any period. Access to
            this Site may be suspended temporarily and without notice in the
            case of system failure, maintenance or repair or for reasons beyond
            our control.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>5. Privacy</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Personal information is governed by our Privacy Policy. The link to
            it and our Cookie policies are here.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We respect the privacy of the users of the Wise Publishing, Inc.
            Sites. Our collection, use and disclosure of Personal Information in
            connection with Wise Publishing, Inc. Sites are governed by the Wise
            Publishing, Inc. Privacy Policy located at{' '}
            <Link to="/privacy-policy/">
              https://www.wisepublishing.com/privacy-policy/
            </Link>
            . See also our Cookie Policy at{' '}
            <Link to="/cookies-policy/">
              https://www.wisepublishing.com/cookies-policy/
            </Link>
            .
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>6. Links to and from Other Websites</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If you use a link on this Site to another website, including
            advertisers’ sites, that site’s terms of use and policies govern. We
            are not liable for anything once you are leave site.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Any links to third party websites, including advertiser websites, on
            Wise Publishing, Inc. Sites are independent of Wise Publishing, Inc.
            and are provided solely for your convenience. If you use any links,
            you leave the Wise Publishing, Inc. Site at your risk. We may not
            have reviewed any such third party websites and will not control or
            be responsible for such websites or their Content or availability;
            therefore, we do not endorse or make any representations, warranties
            or conditions about any links provided to third party websites, or
            any material found there, or any results that may be obtained from
            using them.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>7. Information Contained on Our Sites</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We do our best to provide timely and correct information but given
            the nature of the information, it is provided without warranty of
            any kind.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            While we endeavour to ensure that the information on Wise
            Publishing, Inc. Sites is correct we do not warrant the accuracy and
            completeness of the material on our Sites. We may make changes to
            the material on our Sites at any time without notice. There is
            Content that is time sensitive and may not remain current. We make
            no commitment to update such material.
          </XsParagraph>
          <XsParagraph>
            The material on Wise Publishing, Inc. Sites is provided 'as is',
            without any conditions, warranties of any kind. Accordingly, to the
            maximum extent permitted by law, we provide you with our Site on the
            basis that we exclude all representations, warranties and conditions
            (including without limitation, the conditions implied by law of
            satisfactory quality, fitness for purpose and the use of reasonable
            care and skill) which but for this legal notice might have effect in
            relation to our Sites.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>8. Subscription Agreement</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            There is a separate agreement for subscribers.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Wise Publishing, Inc. offers an enhanced Service for subscribers.
            Certain Services and Content are available only to Subscribers and are
            provided upon acceptance of the{' '}
            <Link to="/subscription-agreement/">Subscription Agreement</Link> and
            these Terms of Use.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>9. License, Copyright and Trademarks</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            All information on the Site is subject to copyright.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Wise Publishing, Inc. takes copyright infringement very seriously.
            Here is the link to our{' '}
            <Link to="/copyright-notice/">Copyright Notice</Link>.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            You can use for your own personal use only and not for Commercial
            Gain.
          </BaseParagraph>
          <BaseParagraph>
            You can print and download and share with a friend in certain
            limited ways. They are listed here.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Subject to the requirement that you must not visit or use Wise
            Publishing, Inc. Sites for the purposes of Commercial Gain, you are
            permitted to print and download extracts from this Site for your own
            personal use or send them to a friend on the following basis:
          </XsParagraph>
          <UnorderedList>
            <li>
              there is no modification in any way of our documents or related
              graphics;
            </li>
            <li>
              no graphics on our Site are used separately from accompanying
              text; and
            </li>
            <li>
              our copyright trademark notices (or our licensor's) and a
              permission notice appear in all copies.
            </li>
          </UnorderedList>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            You cannot use for any AI training, reproduction or licensing/sublicensing.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            For avoidance of doubt, You shall not reproduce and/or otherwise use the content
            in any manner for purposes of training artificial intelligence technologies to
            generate text, including without limitation, technologies that are capable of
            generating works in the same style or genre as the content, unless there is express
            permission to do so from Wise Publishing. You shall not sublicense others to
            reproduce and/or otherwise use content in any manner for purposes of training
            artificial intelligence technologies to generate text without specific and
            express permission.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If you want permission to use for non-personal use, here is who you
            contact.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Any use of any extracts from Wise Publishing, Inc. Sites other than
            in accordance with the above for any purpose is prohibited. Subject
            to the above, no part of Wise Publishing, Inc. Sites may be
            reproduced or stored in any other website or included in any public
            or private electronic retrieval system or service without our prior
            written permission.
          </XsParagraph>
          <XsParagraph>
            For further information or to request permission to republish,
            contact: licensing@wisepublishing.com
          </XsParagraph>
          <XsParagraph>
            Any rights not expressly granted in this legal notice are reserved.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>10. Usage Restrictions</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            As stated above, Content is for personal use only and solely the
            property of Wise Publishing, Inc.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You may use our Sites only for lawful, personal and non-commercial
            purposes. You may download or copy the Content displayed on our
            Sites for your personal use only. No right, title or interest in any
            downloaded materials or software is transferred to you as a result
            of any such downloading or copying. Of course, you may encourage
            others to access the information themselves on a Wise Publishing,
            Inc. Site and tell them how to find it.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            You may not use the Site for anything unlawful, harmful to others,
            targeting vulnerable sectors or sending spam.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>You may not use our Sites:</XsParagraph>
          <UnorderedList>
            <li>
              in any way that breaches any applicable local, national or
              international law or regulations;
            </li>
            <li>
              in any way that is unlawful, fraudulent, or has any unlawful or
              fraudulent purpose or effect;
            </li>
            <li>
              for the purpose of harming or attempting to harm others in any
              way;
            </li>
            <li>
              to target in any way any vulnerable sectors, such as minors;
            </li>
            <li>
              to transmit, or procure the sending of, any unsolicited or
              unauthorized advertising or promotional material or any other form
              of similar solicitation (spam).
            </li>
          </UnorderedList>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            You will not access the site by automated means without permission.
          </BaseParagraph>
          <BaseParagraph>
            You will not interfere with the operation of our Site in any way.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You agree that you will not use any robot, spider, scraper or other
            automated means to access this Site for any purpose except with our
            express written permission. Additionally, you agree that you will
            not:
          </XsParagraph>
          <UnorderedList>
            <li>
              take any action that imposes, or may impose in our sole discretion
              an unreasonably or disproportionately large load on our
              infrastructure;
            </li>
            <li>
              interfere or attempt to interfere with the proper working of our
              Sites or any activities conducted on our Sites; or
            </li>
            <li>
              bypass other measures we may use to prevent or restrict access to
              our Sites. We do not authorize you to extract or reutilize
              substantial parts of our Sites, or to make systematic and repeated
              extractions or reutilizations of insubstantial parts of our Sites.
            </li>
          </UnorderedList>
          <XsParagraph>
            We shall fully cooperate with any law enforcement authorities or
            court order requesting or directing us to disclose the identity or
            locate anyone posting any material in breach of these Terms of Use.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>11. Submissions and Postings</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Any Contributions you submit are considered non-confidential and
            non-proprietary.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Other than Personal Information, which is covered under the Privacy
            Policy, any material or articles you transmit or post ("
            <strong>Contributions</strong>") to a Wise Publishing, Inc. Site
            shall be considered non-confidential and non-proprietary.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            You retain your rights, for any Contribution(s) you make but you
            provide us with a license to use it in anyway without compensation.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You retain your rights to any Contributions you submit, post or
            display on or through our Site. By submitting, posting or displaying
            Contributions on or through our Site, you grant us a worldwide,
            irrevocable, perpetual, unrestricted, non-exclusive, royalty-free
            licence (with the right to sublicense) to use, copy, reproduce,
            process, adapt, modify, translate, perform, publish, transmit,
            create derivative works from, display, distribute (including,
            without limitation, making available on-line, electronically
            transmitting or otherwise communicating to the public) and otherwise
            use and exploit such Contributions in any and all media or
            distribution methods (now known or later developed) for any purpose
            (including for commercial or promotional purposes) without notice or
            compensation and you hereby waive all moral or author's rights in
            such Contributions. We shall have no obligations with respect to
            such Contributions.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Wise Publishing, Inc. retains the right to modify or not use any
            Contributions.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Wise Publishing, Inc. Site retains the right in its sole and
            unfettered discretion at all times (but will not have an obligation)
            to remove, modify or refuse to distribute any Contributions on our
            Sites, to suspend or terminate users, and to reclaim usernames
            without liability to you.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Any reliance on Contributions is at your own risk.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Further, any use or reliance on any Contributions or materials
            posted via our Site or obtained by you through a Wise Publishing,
            Inc. Site is at your own risk. We do not endorse, support, represent
            or guarantee the completeness, truthfulness, accuracy, or
            reliability of any Contributions or communications posted via our
            Sites or endorse any opinions expressed via our Sites.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Please don’t include any Personal Information in Contributions.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            For your own safety and security, we ask you not to reveal any
            Personal Information about yourself or anyone else.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            You agree to indemnify us and hold us harmless for anything
            connected with your Contribution(s).
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            You agree to indemnify us against any loss or damage suffered as a
            result of your Contributions and to hold us, our directors,
            officers, employees and agents and our affiliates and their
            directors, officers, employees and agents, harmless from any and all
            claims, liabilities, damages, costs and expenses, including
            reasonable legal fees, in any way arising from, related to or in
            connection with your use of our Sites, your violation of these Terms
            of Use or the posting or transmission of any material on or through
            our Sites by you, including, but not limited to, any third party
            claim that any information or materials you provide infringes any
            third party proprietary right.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>12. Violations of the Terms of Use</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If you violate these Terms of Use, we may take the actions outlined
            here.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We will determine, in our discretion, whether there has been a
            breach of these Terms of Use through your use of our Sites. When a
            breach has occurred, we may take such action as we deem appropriate.
          </XsParagraph>
          <XsParagraph>
            Failure to comply with these Terms of Use constitutes a material
            breach, and may result in our taking all or any of the following
            actions:
          </XsParagraph>
          <UnorderedList>
            <li>
              immediately, temporary or permanent withdrawal of your right to
              use our Sites;
            </li>
            <li>
              immediately, temporary or permanent removal of any Contributions
              made by you to our Sites; issue of a warning to you;
            </li>
            <li>
              requiring you to immediately destroy any downloaded or printed
              extracts from our Sites.
            </li>
            <li>
              legal proceedings against you for reimbursement of all costs on an
              indemnity basis (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach;
            </li>
            <li>further legal action against you;</li>
            <li>
              disclosure of such information to such law enforcement authorities
              as we reasonably feel is necessary.
            </li>
          </UnorderedList>
          <XsParagraph>
            We exclude liability for actions taken in response to breaches of
            these Terms of Use. The responses described in these Terms of Use
            are not limited, and we may take any other action we reasonably deem
            appropriate.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>13. Disclaimer of Liability</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We are not liable to you or any third party for any loss whatsoever.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR
            DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AFFILIATES AND
            LICENSORS AND ANY PARTY INVOLVED IN CREATING, PRODUCING OR
            DELIVERING OUR SITES OR ANY ASPECT THEREOF WILL NOT BE LIABLE TO YOU
            OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES, LOSS OF PROFITS, DATA, USE,
            GOOD-WILL, OR OTHER INTANGIBLE LOSSES, IN CONNECTION WITH OUR SITES
            IN ANY WAY OR IN CONNECTION WITH THE USE, INABILITY TO USE OR THE
            RESULTS OF USE OF OUR SITES, ANY WEBSITES LINKED TO OUR SITES OR THE
            MATERIAL ON SUCH WEBSITES INCLUDING BUT NOT LIMITED TO LOSS OR
            DAMAGE DUE TO VIRUSES THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
            SOFTWARE, DATA OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE
            OF, OR BROWSING OUR SITES OR YOUR DOWNLOADING OF ANY MATERIAL FROM
            OUR SITES OR ANY WEBSITES LINKED TO OUR SITES. IF YOUR USE OF
            MATERIAL ON OUR SITES RESULTS IN THE NEED FOR SERVICING, REPAIR OR
            CORRECTION OF EQUIPMENT, SOFTWARE OR DATA, YOU ASSUME ALL COSTS
            THEREOF. YOU ALSO AGREE THAT ACCESS TO AND USE OF OUR SITES AND THE
            CONTENT THEREIN IS AT YOUR SOLE RISK.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>14. Jurisdictional Clause</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            These Terms of Use are governed by the Law of Ontario and of Canada.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            This legal notice and any dispute or claim arising out of or in
            connection with it or its subject matter will be governed by and
            construed in accordance with the laws of the province of Ontario and
            the laws of Canada applicable therein, without giving effect to
            their conflict of laws principles.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Any dispute shall be brought in the courts of Ontario or Canada.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            The parties irrevocably submit to the jurisdiction of the courts of
            Ontario, or Canada, as the case may be, for any dispute or claim
            that arises out of or in connection with this legal notice or its
            subject matter.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>15. Termination</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We may restrict use and access of the Site in whole or in part if we
            determine any failure to comply with these Terms.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We may modify, discontinue or terminate these Terms of Use (in part
            or in whole) and deny you access to any or all of your use of a Wise
            Publishing, Inc. Site (or any portion thereof) at any time,
            immediately and without notice, if in our sole discretion, you fail
            to comply with any term or condition in these Terms of Use.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>16. Contact and Notices</BaseTitle>
        </Column>
      </Row>
      <Row marginBottom="144">
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>Our contact information.</BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            To contact Wise Publishing, Inc. in connection with these Terms of
            Use or the Wise Publishing, Inc. Sites, please send an email to
            hello@wisepublishing.com or write to 40 Eglinton Avenue East, Suite
            203, Toronto, Ontario, M4P 3A2.
          </XsParagraph>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default TermsOfUse;
